<template>
  <lang-router-link :to="link" class="eco-cta -video" @click="$emit('play')">
    <div class="eco-cta__content">
      <h2 class="eco-cta__title">{{ title }}</h2>
      <p class="eco-cta__subtitle">{{ subtitle }}</p>
      <icon id="play" class="eco-cta__button"/>
    </div>
  </lang-router-link>
</template>

<script>
export default {
  name: 'OrganisationCta',
  props: {
    title: { type: String, require: true },
    subtitle: { type: String, require: true },
    link: { type: Object }
  },
}
</script>
