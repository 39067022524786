export default {
  buttons: {
    cancel: 'Annuler',
    connection: 'Se connecter',
    linkReset: 'Envoyer un lien',
    back: 'Retour à la connexion',
    subscription: "S'inscrire",
    send: 'Envoyer le message',
    confirmReset: 'Réinitialiser le mot de passe'
  },
  links: {
    reset: 'Mot de passe oublié ?'
  },
  name: {
    label: 'Nom complet',
    empty: 'Veuillez inscrire votre nom complet.',
    pattern: 'Veuillez inscrire votre nom complet.'
  },
  email: {
    label: 'Adresse courriel',
    empty: 'Veuillez inscrire votre adresse courriel.',
    pattern: 'Veuillez inscrire une adresse courriel valide.'
  },
  password: {
    label: 'Mot de passe',
    empty: 'Veuillez inscrire votre mot de passe.'
  },
  organisation: {
    label: 'Entreprise / Organisation',
    empty: 'Veuillez inscrire le nom de votre entreprise.',
    pattern: 'Veuillez inscrire le nom de votre entreprise.'
  },
  orgType: {
    label: "Type d'entreprise",
    default: 'Sélectionner un type',
    empty: 'Veuillez sélectionner un type.',
    pattern: 'Veuillez sélectionner un type valide.'
  },
  object: {
    label: 'Sujet',
    default: 'Je veux...',
    empty: 'Veuillez sélectionner un sujet.',
    pattern: 'Veuillez sélectionner un sujet valide.'
  },
  phone: {
    label: 'Téléphone (facultatif)',
    empty: 'Veuillez inscrire votre numéro de téléphone.',
    pattern: 'Veuillez inscrire un numéro de téléphone valide.'
  },
  message: {
    label: 'Message',
    empty: 'Veuillez rédiger un message.',
    pattern: 'Veuillez rédiger un message.'
  },
  marketing: {
    label: "J'accepte de recevoir des courriels promotionnels de la part de Vitrine IA Québec."
  },
  linkReset: {
    title: 'Demander un lien de réinitialisation',
    confirmation: "Un lien de réinitialisation vient d'être envoyé"
  },
  reset: {
    title: 'Réinitialisation du mot de passe',
    confirmation: 'Votre mot de passe a été réinitialisé avec succès',
    newPassword: {
      label: 'Nouveau mot de passe',
      empty: 'Doit contenir un minimum 8 caractères dont au moins 1 majuscule, 1 minuscule et 1 chiffre.',
      pattern: 'Doit contenir un minimum 8 caractères dont au moins 1 majuscule, 1 minuscule et 1 chiffre.'
    },
    confirmPassword: {
      label: 'Confirmer mot de passe',
      empty: 'La confirmation doit être identique à votre nouveau mot de passe.',
      pattern: 'La confirmation doit être identique à votre nouveau mot de passe.'
    },
    politic: {
      content:
        '<p>Votre mot de passe doit contenir au moins:</p><ul><li>une lettre majuscule</li><li>une lettre minuscule</li><li>un chiffre</li></ul>'
    }
  }
}
