<template>
  <div class="input">
    <label v-if="label" :for="fieldMeta.id" :class="labelClassName">{{ label }}<span v-if="isRequired">*</span></label>

    <textarea
      v-if="textarea"
      :id="fieldMeta.id"
      :value="value"
      :name="fieldMeta.name"
      :class="fieldClassName"
      @input="onInput"
      @blur="$emit('single-validate', name)"
    />

    <input
      v-else
      :id="fieldMeta.id"
      :ref="fieldMeta.name"
      :name="fieldMeta.name"
      :type="showEye ? currentType : type"
      :value="value"
      :class="fieldClassName"
      @input="onInput"
      @blur="$emit('single-validate', name)"
    />
    <button type="button" v-if="showEye" class="input__button" @click="toggleType">
      <icon :id="currentType === 'password' ? 'eye' : 'eye_unsee'"/>
    </button>
    <p v-if="error && showValidation" :class="errorClassName">
      <Icon id="warning" class="input__warning" />{{ errorMessage }}
    </p>
  </div>
</template>

<script>
import randomId from 'random-entities/id'

export default {
  name: 'InputText',
  props: {
    id: { type: String, default: null },
    name: { type: String, default: null },
    label: { type: String, default: null },
    value: { type: String, default: null },
    inputClass: { type: [String, Array, Object], default: Array },
    labelClass: { type: [String, Array, Object], default: Array },
    errorClass: { type: [String, Array, Object], default: Array },
    textarea: { type: Boolean, default: false },
    showValidation: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: null },
    isRequired: { type: Boolean, default: false },
    hasFocus: { type: Boolean, default: false },
    isPassword: { type: Boolean, default: false },
    isNumber: { type: Boolean, default: false },
    showEye: { type: Boolean, default: false }
  },
  data() {
    const generatedId = randomId()
    const id = this.id || generatedId

    return {
      fieldMeta: { id, name: this.name || id },
      currentType: 'password'
    }
  },
  computed: {
    fieldClassName() {
      return [
        'input__field',
        this.inputClass,
        { '-error': this.error && this.showValidation, '-textarea': this.textarea }
      ]
    },
    labelClassName() {
      return ['input__label', this.labelClass]
    },
    errorClassName() {
      return ['input__error', this.errorClass]
    },
    type() {
      if (this.isPassword) {
        return 'password';
      } else {
        return this.isNumber ? 'number' : 'text'
      }
    }
  },
  mounted() {
    if (this.hasFocus)
      this.$nextTick(function () {
        const ref = document.getElementById(this.fieldMeta.id)
        ref.focus()
      })
  },
  methods: {
    toggleType() {
      if (this.currentType === 'password') this.currentType = 'text';
      else this.currentType = 'password';
    },
    onInput({ target }) {
      this.$emit('input', target.value)
    }
  }
}
</script>
