<template>
  <div :class="['mobile-nav-panel', { '-isauth': isAuth }]">
    <div class="mobile-nav-panel__content">
      <nav class="mobile-nav-panel__links">
        <div class="mobile-nav-panel__buttons">
          <!-- <template v-if="showLogin">
            <LangRouterLink v-if="!isAuth" :to="openLoginPanelLink" class="button -link -halfwidth">
              {{ $t('app.portalButton') }}
            </LangRouterLink>
            <button v-else class="button -halfwidth" @click="logout">{{ $t('app.logoutButton') }}</button>
          </template> -->
          <LangRouterLink :to="{ name: 'vitrineSpace' }" class="button -link -dark -fullwidth">{{ $t('app.vitrine') }}</LangRouterLink>
        </div>

        <div  class="mobile-nav-panel__drop" @click="repertoryOpen = !repertoryOpen">
          <div class="mobile-nav-panel__link -nomargin">
            <img src="/badges/business.svg" alt="" class="mobile-nav-panel__badge"/>
            {{ $t('app.nav.repertory') }}
              <Icon id="arrow-drop" class="navbar__dropdown--icon -mobile" :class="{'-reverse': repertoryOpen}"/>
          </div>
          <DropDown :is-open="repertoryOpen" >
            <div class="mobile-nav-panel__drop-panel">
              <LangRouterLink class=" link" :to="{ name: 'businessIndex' }">
                {{ $t('app.instant.menu.complete') }}
              </LangRouterLink>
              <LangRouterLink class="link" :to="{ name: 'instant' }">
                {{ $t('app.instant.menu.overview') }}
              </LangRouterLink>
            </div>
          </DropDown>
        </div>

        <LangRouterLink class="mobile-nav-panel__link" :to="{ name: 'studyIndex' }"
        ><img src="/badges/study.svg" alt="" class="mobile-nav-panel__badge"/>{{
            $t('app.nav.studies')
          }}
        </LangRouterLink
        >

        <div  class="mobile-nav-panel__drop" @click="toolsOpen = !toolsOpen">
          <div class="mobile-nav-panel__link -nomargin">
            <img src="/badges/tools.svg" alt="" class="mobile-nav-panel__badge"/>
            {{ $t('app.nav.tools') }}
            <Icon id="arrow-drop" class="navbar__dropdown--icon -mobile" :class="{'-reverse': toolsOpen}"/>
          </div>
          <DropDown :is-open="toolsOpen" >
            <div class="mobile-nav-panel__drop-panel">
              <LangRouterLink class=" link" :to="{ name: 'maturity' }">
                {{ $t('app.nav.maturity') }}
              </LangRouterLink>
              <LangRouterLink class=" link" :to="{ name: 'documentIndex' }">
                {{ $t('documents.page') }}
              </LangRouterLink>
            </div>
          </DropDown>
        </div>

        <a target="_blank" rel="noopener" class="mobile-nav-panel__link" :href="moduleUrl">
          <img src="/badges/eco.svg" alt="" class="mobile-nav-panel__badge"/>{{ $t('app.nav.ecosystem') }}
        </a>

        <LangSwitchLink
            v-for="lang in languageLinks"
            :key="lang"
            :lang="lang"
            :params="getParams(lang, storeObjectKey)"
            class="mobile-nav-panel__lang"
        >{{ getLangLabel(lang) }}
        </LangSwitchLink
        >
      </nav>

      <Socials/>
    </div>
  </div>
</template>

<script>
import {LangSwitchMixin} from '@/mixins'
import http from '@/extensions/Http'
import {Socials} from '@/components/partials'

export default {
  name: 'MobileNavPanel',
  components: {Socials},
  mixins: [LangSwitchMixin],
  data() {
    return {
      repertoryOpen: false,
      toolsOpen: false
    }
  },
  computed: {
    showLogin() {
      if (this.$store.state.user && this.$store.state.user.role === 'BusinessOwner') return false
      return true
    },
    languageLinks() {
      return this.$lang.list.filter(langCode => langCode !== this.$lang.current)
    },
    isAuth() {
      return this.$store.state.user.isAuth
    },
    openLoginPanelLink() {
      return {
        name: this.$route.name,
        query: {...this.$route.query, connexion: true}
      }
    },
    moduleUrl() {
      return this.$lang.current === 'fr' ? process.env.VUE_APP_MODULE_URL : `${process.env.VUE_APP_MODULE_URL}/en`
    },
    storeObjectKey() {
      if (this.$route.name.indexOf('studyShow') !== -1) return 'studies'
      if (this.$route.name.indexOf('businessShow') !== -1) {
        return 'businesses'
      }
      return null
    }
  },
  beforeDestroy() {
    this.$scroll.restore()
  },
  methods: {
    logout() {
      http.$post('/api/logout').then(() => {
        this.$store.dispatch('updateIsAuth', {bool: false})
      })
    },
    getLangLabel(abbrev) {
      return abbrev === 'en' ? 'English' : 'Français'
    }
  }
}
</script>
