<template>
  <div :class="{ '-loading': !$page || !$beet.isReady }">
    <template v-if="$page && $beet.isReady">
      <header class="header -padding" :style="backgroundImage">
        <div v-scroll:reveal="{ delay: 450 }">
          <Icon id="space" class="header__badge"/>
        </div>
        <h1 v-scroll:reveal="{ delay: 550 }" class="header__title">{{ $page.header.title }}</h1>
        <h2 v-scroll:reveal="{ delay: 650 }" class="header__subtitle">{{ $page.header.subtitle }}</h2>
      </header>

      <section class="instant-link__flex">
        <InstantLink
          icon="/badges/space_1.svg"
          :title="$beet.options.ctos.mvLogin.title"
          :text="$beet.options.ctos.mvLogin.subtitle"
          :button-label="$beet.options.ctos.mvLogin.linkLabel"
          :button-link="loginLink"
          class="-space"
        />
        <InstantLink
          icon="/badges/space_2.svg"
          :title="$beet.options.ctos.mvEdit.title"
          :text="$beet.options.ctos.mvEdit.subtitle"
          :button-label="$beet.options.ctos.mvEdit.linkLabel"
          button-route="spaceForm"
          class="-space"
        />
        <InstantLink
          icon="/badges/space_3.svg"
          :title="$beet.options.ctos.mvAdd.title"
          :text="$beet.options.ctos.mvAdd.subtitle"
          :button-label="$beet.options.ctos.mvAdd.linkLabel"
          button-route="spaceForm"
          class="-space"
        />
      </section>

      <VideoCta
        :title="$beet.options.ctos.ecosystemVideo.title"
        :subtitle="$beet.options.ctos.ecosystemVideo.subtitle"
        :link="openVideoModal"
      />

      <EcoCta
        class="-margin-top"
        :title="$beet.options.ctos.ecosystemExplore.title"
        :subtitle="$beet.options.ctos.ecosystemExplore.subtitle"
        :link-label="$beet.options.ctos.ecosystemExplore.linkLabel"/>
    </template>
  </div>
</template>

<script>
import InstantLink from "@/components/partials/InstantLink.vue";
import VideoCta from "@/components/partials/VideoCta.vue";
import EcoCta from "@/components/partials/EcoCta.vue";
import { PageMetaMixin } from "@/mixins";

export default {
  name: "VitrineSpace",
  beetPage: "mavitrine",
  components: { EcoCta, InstantLink, VideoCta },
  mixins: [PageMetaMixin],
  computed: {
    loginLink() {
      return process.env.VUE_APP_API_URL;
    },
    backgroundImage() {
      return this.$device.tablet
        ? `background-image: url('/images/banner_vitrineSpace.png')`
        : `background-image: url('/images/banner_vitrineSpace_m.png')`
    },
    openVideoModal() {
      return {
        name: this.$route.name,
        query: { ...this.$route.query, video: true }
      }
    }
  }
};
</script>
